import { FullPageElement, html, unsafeHTML, css, formCss } from 'Elements';
import { Fetcher, UrlParams } from 'Utils';
import App from 'App';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import config from './config.js';
import './detail.js';

const tableCss = css`
  table {
    width:100%;
    border-collapse: collapse;
    box-sizing: border-box;
    font-size:0.9em;
    text-align:left;
  }

  table.disabled {
    pointer-events:none;
    filter: grayscale(100%);
    opacity:0.5;
  }

  table thead {
    font-weight:bold;
    height:40px;
    align-items:center;
    background-color: var(--sl-color-neutral-100);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 8px -2px;
    top:0px;
    position:sticky;
    z-index:10;
  }

  table th {
    border: 1px solid var(--sl-color-neutral-200);
    padding-left: 10px;
  }

  table tr {
    box-sizing: border-box;
    border-radius:5px;
  }

  table td {
    padding: 7px;
    border:1px solid var(--sl-color-neutral-200);
  }

  table td.right {
    padding-right:10px;
    text-align:right;
  }

  table td.icon {
    width:30px;
    text-align:center;
    font-size:1.8em;
    font-weight:bold;
    padding:0;
  }

  table td.icon m-icon {
    opacity:1;
    transition: none;
  }

  table tbody tr:nth-child(even) td {
    
  }

  table tbody tr:nth-child(odd) td {
    background-color: var(--table-list-odd-background-color)
  }

  table tbody tr.line {
    cursor:pointer;
  }

  table tbody tr.line:hover {
    outline:2px ridge var(--sl-color-primary-200);
    outline-offset: -1px;
  }

  table tbody tr.selected {
    outline:2px ridge var(--sl-color-primary-500);
    outline-offset: -1px;
  }

  table tbody tr:hover td.icon m-icon {
    opacity:1;
  }

  table tr {
    padding:0;
  }

  table td {
    padding:0px;
    padding-left: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  td.grouper {
    background-color: var(--sl-color-neutral-100) !important;
  }

  .group-header {
    background-color: var(--table-list-group-background-color);
    font-size: 1em;
    padding:5px;
    padding-right:10px;
    padding-left: 10px;
    color:white;
    text-transform: capitalize;
    display:inline-block;
    border-radius: 20px;
    width:200px;
    margin-top:20px;
    margin-bottom:20px;
    margin-left:0px;
  }

  .events {
    display:flex;
    flex-wrap:wrap;
    gap:5px;
    padding:5px;
  }

  .events m-icon {
    font-size:1.4em;
  }

  .events sl-tag::part(base) {
    padding-left:3px;
    font-size:0.9em;
  }

  .events sl-tag m-icon {
    margin-right:5px;
    font-size:1.7em;
    padding-left:0px;
  }

  .pagesnav {
    display:flex;
    justify-content:space-between;
    align-items:baseline;
    gap:10px;
    margin:5px;
    min-height:30px;
  }

  .pagesnav .title {
    font-size:0.9em;
  }

  .empty {
    display:flex;
    width:100;
    justify-content:center;
    text-align:center;
    gap:10px;
    padding:20px;
    flex-direction:column;
  }

  .empty m-icon {
    font-size:3em;
  }

  em {
    font-style: normal;
    background-color: var(--sl-color-yellow-200);
    padding-left:2px;
    padding-right:2px;
  }

  @media (max-width: 670px) {
    .header {
      flex-direction: column;
      gap: 10px;
    }
  }

`
class LogsTable extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      tableCss
    ];
  }

  static get translations() {
    return [
      super.translations,
      config.translations,
      {
        english: {
          translation: {
            event: 'event',
            date: 'When?',
            who: 'Who?',
            empty: 'No event available',
          }
        },
        french: {
          translation: {
            event: 'évènement',
            date: 'Quand?',
            who: 'Qui?',
            empty: 'Aucun évènement disponible',
          }
        }
      }
    ];
  }

  static get properties() {
    return {
      items: { type: Array },
      displayedItems: { type: Array },
      groupBy: { type: String },
      newEvents: { type: Number },
    };
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.items = null;
    this.page = parseInt(UrlParams.get('p', 1));
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.setDayJsLocale();
    window.addEventListener('language-changed', this.onLanguageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  setDayJsLocale() {
    dayjs.locale(App.config.lg);
  }

  async onLanguageChanged() {
    this.setDayJsLocale();
  }

  async visibleCallback() {
    super.visibleCallback();
    if (this.items === null) {
      await this.reloadData();
    }
  }

  async pushData(data) {
    if (this.loading) return;
    if (this.items) {
      const levels = UrlParams.get('level' ,'info,error,warn,debug');
      if (levels.includes(data.level)) {
        this.items.unshift(data);
        if (this.items.length > 100) this.items.pop();
        this.requestUpdate();
        if (data.level === 'error' || data.level === 'warn') {
          window.dispatchEvent(new CustomEvent('playsound', { detail:'error' }));
        } else {
          window.dispatchEvent(new CustomEvent('playsound', { detail:'notif' }));
        }
      } 
    }
  }

  async reloadData() {
    this.loading = true;
    this.loader = this.loader || this.parent.qs('sl-progress-bar');
    this.loader.style.visibility = 'visible';
    let url = this.apiEndpoint;
    const params = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(params.entries());
    const response = await Fetcher.post(url, searchParams);
    
    this.items = response?.data || [];
    this.meta = response?.meta;
    this.loading = false;
    this.loader.style.visibility = 'hidden';
  }

  groupEvents(items) {
    const grouped = {};
    items.forEach(event => {
      const createdDate = dayjs(event.date);
      let key;

      if (this.groupBy === 'day') {
        key = createdDate.format('dddd DD/MM/YYYY');
      } else if (this.groupBy === 'week') {
        const startOfWeek = createdDate.isoWeek();
        key = `Semaine ${startOfWeek}: ${createdDate.startOf('isoWeek').format('DD/MM')} - ${createdDate.endOf('isoWeek').format('DD/MM')}`;
      } else if (this.groupBy === 'month') {
        key = createdDate.format('MMMM YYYY');
      }

      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(event);
    });
    return grouped;
  }


  panelOpen(ev, item) {
    this.modalDetail = this.modalDetail || this.qs('sys-logs-detail');
    UrlParams.set(this.urlVar, item.id);
    this.modalDetail.item = null;
    this.modalDetail.item = item;
  }

  panelClose(ev) {
    UrlParams.del(this.urlVar);
  }

  pick(from, s) {
    return s
        .replace(/\[([^\[\]]*)\]/g, ".$1.")
        .split(".")
        .filter((t) => t !== "")
        .reduce((prev, cur) => prev && prev[cur], from)
  }

  renderColumnLevel(item) {
    return html`<sl-tag size="small" variant="${config.levels[item.level]?.color}">${item.level.toUpperCase()}</sl-tag>`;
  }

  renderColumnFrom(item) {
    return html`<sl-tag size="small" variant="${config.froms[item.from]?.color}">${item.from.toUpperCase()}</sl-tag>`;
  }

  renderColumnDate(item) {
    if (this.groupBy === 'day') {
      return dayjs(item.date).format('HH:mm');
    } else {
      return dayjs(item.date).format('DD/MM/YYYY HH:mm');
    }
  }

  renderColumnMsg(item) {
    return (item.highlight?.msg || item.msg || '').toString();
  }


  renderColumnLogger(item) {
    return html`<sl-tag size="small" variant="info">${item.logger}</sl-tag>`;
  }

  renderItems() {
    if (this.loading) {
      return '';
    }

    if (!this.items?.length) {
      return html`
        <div class="empty">
          <m-icon name="troubleshoot" size="big"></m-icon>
          <div>${this._tl('empty')}</div>
        </div>
      `;
    }

    const groupedItems = this.groupEvents(this.items);

    return html`
      <table ?disabled=${this.loading}>
        <tbody>
          ${Object.keys(groupedItems).map(group => html`
            <tr>
              <td colspan="100" class="grouper">
                <div class="group-header">${group}</div>
              </td>
            </tr>
            ${groupedItems[group].map(item => html`
              <tr class="line" @click=${ev => this.panelOpen(ev, item)}>
                <td width="70">${this.renderColumnFrom(item)}</td>
                <td width="70">${this.renderColumnLevel(item)}</td>
                <td width="70">${this.renderColumnLogger(item)}</td>
                <td>${unsafeHTML(this.renderColumnMsg(item))}</td>
                <td class="right">${this.renderColumnDate(item)}</td>
              </tr>
            `)}
          `)}
        </tbody>
      </table>
    `;
  }

  renderPagesNav() {
    const totalCount = this.meta?.totalCount || 0;
    const totalText = totalCount >= 1 ? `${this._tl('event')}s` : this._tl('event');
    return html`
      <div class="pagesnav">
        <div class="title">${totalCount} ${totalText}</div>
        <data-paginator pages=${this.meta?.totalPages} value=${this.page} @change=${this.onPaginatorChange}></data-paginator>
      </div>
    `;
  }

  onPaginatorChange(ev) {
    this.page = ev.detail;
    UrlParams.set('p', this.page);
    this.reloadData();
  }

  render() {
    return html`
      ${this.renderPagesNav()}
      ${this.renderItems()}
      <sys-logs-detail @close=${this.panelClose}></sys-logs-detail>
    `;
  }
}

customElements.define('sys-logs-table', LogsTable);
